<template>
  <v-container class="all">
    <v-row class="d-flex align-start">
      <v-col lg="9" class="my-5 pr-5">
        <h1 class="indigo--text text--lighten-1 text-center">
          RETIRO ETÉRICO ABIERTO
        </h1>
        <p class="text-center">15 de septiembre al 14 de octubre</p>
        <div class="d-flex justify-center mb-5">
          <v-img
            max-width="30%"
            src="../../public/arcangel_miguel.jpg"
          ></v-img>
        </div>
        <p v-html="retiroAbierto" class="text-center"></p>
      </v-col>
      <v-col lg="3" class="pl-2"><PanelDerecho /></v-col>
    </v-row>
  </v-container>
</template>
<script>
import PanelDerecho from "../components/Inicio/PanelDerecho";
export default {
  name: "RetiroEterico",
  components: {
    PanelDerecho,
  },
  data() {
    return {
      retiroAbierto:"Templo de la Fe Iluminada y Protección<br><br>Lake Louise, Banff, Canadá<br><br>AMADO ARCÁNGEL MIGUEL<br><br>ARCÁNGEL DEL PRIMER RAYO-AZUL - PRÍNCIPE DE LOS ARCÁNGELES<br><br>PRÍNCIPE DE LAS HUESTES ANGÉLICAS."
    }
  },
};
</script>
